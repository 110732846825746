/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useHttpService from '../HttpService';
import AudioRecorder from './AudioRecorder';
import { getLoggedInUserId, calculateResult, levelDotsImages } from '../HelperMethods';
import Issues from './Issues';
import LoadingSpinner from './LoadingSpinner';
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { toast } from "react-toastify";
import HighlightLessonIssues from '../components/HighlightLessonIssues'
import SuggestedLessons from './SuggestedLessons';
import englishImg from '../assets/images/english.svg';
import infoImg from '../assets/images/i-button.svg';
import speechTypeImage from '../assets/images/speech-type.svg';
import Tooltip from './Tooltip';
import ErrorMessage from "../assets/images/error-mesgg.svg";
import ShowModal from "../components/ShowModal";

const UserLesson = () => {
    const { id } = useParams();
    const loggedInUserId = getLoggedInUserId();
    const [userLesson, setUserLesson] = useState({});
    const [startLesson, setStartLesson] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [processedIssueId, setprocessedIssueId] = useState(0);
    const [spokenText, setSpokenText] = useState('');
    const [showStopButton, setShowStopButton] = useState(false);
    const connectionRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();
    const httpService = useHttpService();
    const fifteenMinutesInMilliseconds = 15 * 60 * 1000;
    const currentTime = new Date();
    const shouldHide = !spokenText && !userLesson?.speechText;
    const startStopButtonTimer = () => {
        setTimeout(() => {
            setShowStopButton(true);
        }, 500);
    };

    useEffect(() => {
        getUserLessonDetail();
    }, []);

    useEffect(() => {
        connectionRef.current = new HubConnectionBuilder()
            .withUrl('/notificationHub')
            .withAutomaticReconnect({
                maxRetries: 3,
                retryInterval: 3000,
            })
            .build();

        connectionRef.current.start()
            .then(() => {
                console.log('SignalR connected');
            })
            .catch((error) => {
                console.log('SignalR connection error:', error);
            });

        connectionRef.current.on('UserLessonProcessed', handleUserLessonProcessed);
        //connectionRef.current.on('UserLessonIssueProcessed', handleUserLessonIssueProcessed);
        connectionRef.current.on('WordLessonIssueProcessed', handleUserLessonIssueProcessed);

        return () => {
            if (connectionRef.current.state === HubConnectionState.Connected) {
                connectionRef.current.off('UserLessonProcessed', handleUserLessonProcessed);
                //connectionRef.current.off('UserLessonIssueProcessed', handleUserLessonIssueProcessed);
                connectionRef.current.off('WordLessonIssueProcessed', handleUserLessonIssueProcessed);
                connectionRef.current.stop().then(() => { console.log('Connection Stoped') });
            }
        };
    }, []);

    const handleUserLessonProcessed = (userId, userLessonId) => {
        if (userId === loggedInUserId) {
            if (userLessonId === id.toString())
                getUserLessonDetail();
            toast.success('Your speech has been analyzed.');
        }
    };

    const handleUserLessonIssueProcessed = (userId, userLessonId, userLessonIssueId) => {
        if (userId === loggedInUserId) {
            if (userLessonId === id.toString())
                getUserLessonDetail();
            setprocessedIssueId(userLessonIssueId);
        }
    };

    const getUserLessonDetail = () => {
        setIsLoading(true);
        httpService
            .get(`/api/userLessons/${loggedInUserId}/detail?userId=${loggedInUserId}&userLessonId=${id}`)
            .then((res) => {
                const modifiedDate = new Date(res.data.modifiedDate);
                if (
                    res.data.status === "InProcess" &&
                    res.data.lessonStatus === "InProgress" &&
                    currentTime - modifiedDate < fifteenMinutesInMilliseconds
                ) {
                    if (currentTime - modifiedDate < fifteenMinutesInMilliseconds) {
                        res.data.status = "New";
                        res.data.lessonStatus = "NotStarted";
                        httpService.put(`/api/userLessons/UpdateState?userLessonId=${id}&status=${res.data.status}&lessonStatus=${res.data.lessonStatus}`);
                    }
                }
                setUserLesson(res.data);
            })
            .catch((err) => {
                if (err?.response?.data) setErrorMessage(err.response.data);
                else if (err.errors) setErrorMessage(err.errors[0]);
                else
                    setErrorMessage(
                        "Something went wrong, please try again or contact support!"
                    );

                setShowModal(true);
                return;
            })
            .finally(() => setIsLoading(false));
    };

    const handleSave = (transcribedText, transcriptionTime, recordedAudio) => {
        if (transcriptionTime < 1 || transcribedText === '') {
            setErrorMessage('Speak for at least 1 second.');
            resetComponent();
            getUserLessonDetail();
            return;
        }
        const formData = new FormData();
        formData.append('spokenText', transcribedText);
        formData.append('speechTime', transcriptionTime);
        formData.append('file', recordedAudio);
        var headers = { 'Content-Type': 'multipart/form-data' };
        setIsLoading(true);
        httpService.post(`/api/userLessons/${loggedInUserId}/analyze?userId=${loggedInUserId}&userLessonId=${id}`, formData, headers)
            .then(res => {
                getUserLessonDetail();
                setStartLesson(false);
                toast.success('Uploaded, wait for analysis');
            })
            .catch(err => {
                if (err?.response?.data)
                    setErrorMessage(err.response.data);
                else if (err.errors)
                    setErrorMessage(err.errors[0]);
                else setErrorMessage('Something went wrong, please try again or contact support!');

                setShowModal(true);
                return;
            })
            .finally(() => setIsLoading(false));
    };


    const handleRetest = (e) => {
        e.preventDefault();
        setIsLoading(true);
        httpService.post(`/api/userLessons/join?userId=${loggedInUserId}&lessonId=${userLesson.lessonId}`)
            .then(res => {
                toast.success('The lesson is reset successfully.');
                navigate('/userLesson/' + res.data);
                window.location.reload();
            })
            .catch(err => {
                if (err?.response?.data)
                    setErrorMessage(err.response.data);
                else if (err.errors)
                    setErrorMessage(err.errors[0]);
                else setErrorMessage('Something went wrong, please try again or contact support!');

                setShowModal(true);
                return;
            })
            .finally(() => setIsLoading(false));
    }

    const resetComponent = () => {
        setUserLesson({});
        setStartLesson(false);
        setIsLoading(false);
        setprocessedIssueId(0);
    };

    const handlelivetranscription = (transcribedText) => {
        setSpokenText(transcribedText);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const removeDuplicateIssuesByWordId = (issues) => {
        var result = issues.filter((issue, index, self) =>
            index === self.findIndex((i) => i.wordId === issue.wordId)
        );
        return result;
    };

    return (
        <>
            {userLesson?.lessonId > 0 &&
                <>
                    <section className="lesson-starts lesson-starttwo new-lesson-start">
                        <div className="container inner-container">
                            <div className="row">
                                <div className="white-bg">
                                    <div className="modal-body lesson-start">
                                        <div className="lesson-header-main-userlesson">
                                            <div className="heading-span">
                                                <h2>{userLesson?.lessonName}</h2>
                                            </div>

                                            <div className="usa-flagg">
                                                <Tooltip text="English">
                                                    <img src={englishImg} alt="" />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className="difficulty-dots">
                                            <img src={levelDotsImages[userLesson?.levelId]} alt="" />
                                        </div>
                                        <div className={`${userLesson?.status === 'Processed' ? 'a-plus-main' : 'work-terminology-main userlesson-terminology'}`}>
                                            <div className={`${userLesson?.status === 'Processed' ? 'a-plus-left' : 'terminology-left'}`}>
                                                <div className={`whiteBox-heading ${userLesson?.status === 'Processed' ? 'lesson-three-dashbord' : ''}`}>
                                                    {userLesson?.status === 'Processed' &&
                                                        <>
                                                            <p className="whiteBox-para"><span className="whiteBox-head">Description: </span> {userLesson?.lessonDescription}</p>
                                                        </>
                                                    }
                                                </div>
                                                {userLesson?.status !== 'Processed' &&
                                                    <p className="whiteBox-para">
                                                        <span className="whiteBox-head">Description: </span>
                                                        {userLesson?.lessonDescription}
                                                    </p>
                                                }
                                                {userLesson?.status !== 'Processed' &&
                                                    <div className="instructions-main">
                                                        <div className="step-one">
                                                            <h2>Step 1 - Click</h2>
                                                            <span className="lesson-start-btn">start</span>
                                                        </div>
                                                        <div className="step-two">
                                                            <h2>Step 2 - Read Text Out Loud</h2>
                                                            <img src={speechTypeImage} alt="" />
                                                        </div>
                                                        <div className="step-three">
                                                            <div className="step-three">
                                                                <h2>Step 3 - Complete to Analyze</h2>
                                                                <span className="red-circle"><span className="white-block"></span></span>
                                                            </div>
                                                        </div>
                                                    </div>}
                                            </div>
                                            {userLesson?.status === 'Processed' &&
                                                <>
                                                    <div className="a-plus-middle">
                                                        <div className="i-button-image">
                                                            <label>Grade</label>
                                                            <a href="/" onClick={(e) => { e.preventDefault() }} title={`Good: ${parseFloat(userLesson.result.good).toFixed(2)}%, Needs Word: ${parseFloat(userLesson.result.needsWork).toFixed(2)}%`} >
                                                                <img src={infoImg} alt="" />
                                                            </a>
                                                        </div>
                                                        <span className={`a-plus ${calculateResult(parseInt(userLesson.result.good)) === 'F' ? 'red-text' : ''}`}>
                                                            {calculateResult(parseInt(userLesson.result.good))}
                                                        </span>
                                                    </div>
                                                    <div className="a-plus-right focusBox-right">
                                                        <div className="terminology-right">
                                                            <h2>Focus:</h2>
                                                            <ol>
                                                                {userLesson?.focus && userLesson.focus.split('\n').map((line, index) => (
                                                                    <li key={index}>
                                                                        <div className="line-heightt">
                                                                            {/* Remove leading hyphens and whitespace from each line */}
                                                                            <span className="levelUp-text">{line.replace(/^[\s-]+/, '')}</span>
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                            </ol>
                                                        </div>

                                                        <div className="a-plus-buttons">
                                                            <a href="/" className="join-lesson retest" onClick={(e) => { e.preventDefault(); navigate('/dashboard') }}>Dashboard</a>
                                                            <a href="/" className="join-lesson retest dashbord-retest" onClick={(e) => { handleRetest(e) }}>Test Again</a>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {userLesson?.status !== 'Processed' &&
                                                <div className="terminology-right">
                                                    <h2>Focus:</h2>
                                                    <ol>
                                                        {userLesson?.focus && userLesson.focus.split('\n').map((line, index) => (
                                                            <li key={index}>
                                                                <div className="line-heightt">
                                                                    {/* Remove leading hyphens and whitespace from each line */}
                                                                    <span className="levelUp-text">{line.replace(/^[\s-]+/, '')}</span>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ol>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className={`read-paragraph ${userLesson?.status === 'Processed' ? 'productions-needs' : ''}`}>
                        <div className="container inner-container">
                            <div className="row">
                                <div className={`read-para-main ${startLesson === false && userLesson?.status !== 'Processed' ? ' blur-para' : ''}`}>
                                    <div className="read-para-left">
                                        <p>
                                            {userLesson?.status !== 'Processed' ? (
                                                userLesson?.lessonParagraph
                                            ) : (
                                                <>
                                                    <HighlightLessonIssues
                                                        paragraph={userLesson?.lessonParagraph}
                                                        wordsToHighlight={userLesson?.issues.map(issue => issue)}
                                                    />
                                                    <br />
                                                </>
                                            )}
                                        </p>
                                        {!shouldHide && (<p>
                                            <br />
                                            <b>Transcribed Text -</b>
                                            <br />
                                            <span style={{ paddingLeft: '7em' }}>
                                                {!spokenText ? userLesson?.speechText : spokenText}
                                            </span>
                                        </p>
                                        )}
                                        {userLesson?.lessonStatus === 'InProgress' && (userLesson?.status === 'InProcess' || userLesson?.status === 'New') && <div className="loading-bar-main-box"><div className="white-loading-box white-loading-modalBox">
                                            <h2>Analyzing</h2>
                                            <figure>
                                                <div className="progres">
                                                    <div className="progres-bar progres-bar-info progres-bar-striped active"></div>
                                                </div>
                                                <figcaption className="info"></figcaption>
                                            </figure>
                                        </div>
                                        </div>}
                                        {startLesson === false && userLesson?.lessonStatus === 'NotStarted' && <div className="lesson-start-button-main"> <a href="/" className="lesson-start-btn" onClick={(event) => { event.preventDefault(); setStartLesson(true); startStopButtonTimer(); }}>Start</a></div>}
                                        {showStopButton && startLesson === true && userLesson?.lessonStatus === 'NotStarted' && <div className="stop-buttonn">
                                            <AudioRecorder
                                                handleSave={(transcribedText, transcriptionTime, recordedAudio) =>
                                                    handleSave(transcribedText, transcriptionTime, recordedAudio)
                                                }
                                                handleStartLesson={setStartLesson}
                                                handlelivetranscription={handlelivetranscription}
                                            />
                                        </div>}
                                    </div>
                                    <div className="read-para-right">
                                        <h2>{userLesson?.status !== 'Processed' ? "Please complete the lesson first!" : "Issues Detected:"}</h2>
                                        {userLesson?.status === 'Processed' && userLesson?.issues?.length > 0 &&
                                            <div className="mb-3">
                                                <Issues
                                                    issues={removeDuplicateIssuesByWordId(userLesson.issues)}
                                                    onReset={handleRetest}
                                                    result={userLesson.result}
                                                    processedIssueId={processedIssueId}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {userLesson?.status === 'Processed' && <SuggestedLessons lessonId={userLesson?.lessonId} />}
                    {isLoading && <LoadingSpinner />}
                    {showModal && (
                        <ShowModal
                            showModal={showModal}
                            handleCloseModal={handleCloseModal}
                            AddClass={"error-message-mainbox fade"}
                        >
                            <div className="error-mesg-main">
                                <div className="del-box">
                                    <img src={ErrorMessage}></img>
                                    <h2>Error</h2>
                                    <span>{errorMessage}</span>
                                    <div className="continoue">
                                        <a
                                            href="/"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowModal(false);
                                            }}
                                        >
                                            Continue
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ShowModal>
                    )}
                </>
            }
        </>
    )
}

export default UserLesson;