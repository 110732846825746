import React, { useState, useEffect } from "react";
import { MediaBaseURL } from '../../HelperMethods';
import englishImg from '../../assets/images/english.svg';
import espanolImg from '../../assets/images/espanol.svg';

const Phonemes = ({ stateObject, handleForm, saveWord, handleUpdate }) => {

    const {
        word,
        flagImage,
        wordPhonomesValues,
        setWordPhonomesValues,
        phonomesValues,
        setPhonomesValues,
        languageId
    } = stateObject;
    const [selectedWord, setSelectedWord] = useState(word);
    const [translationId, setTranslationId] = useState(0);
    const [wordSyllable, setWordSyllable] = useState([]);
    const [wordRepeat, setWordRepeat] = useState("");
    useEffect(() => {
        setWordRepeat(word.repeat?.split('-'))
        setSelectedWord(word);
        const wordTranslation = word.translations.find(trans => trans.languageId == languageId);
        if (wordTranslation) {
            setTranslationId(wordTranslation.translationId);
        }
        else {
            setTranslationId('');
        }
        setWordSyllable(word.syllable.split('-'));
    }, [phonomesValues,languageId,word]);

    function handleTranslationInputChange(e, index) {
        const newValue = e.target.value;
        setPhonomesValues((prevValues) => ({
            ...prevValues,
            [index]: newValue
        }));
    }

    function onBlurChange(e, selectedWord, index) {
        let phonemesArray = selectedWord.phonemes.split('-');
        phonemesArray[index] = e.target.value;
        selectedWord.phonemes = phonemesArray.join('-');
        saveWord(e, selectedWord, index);
    }

    function handleWordInputChange(e, index) {
        const newValue = e.target.value;
        setWordPhonomesValues((prevValues) => {
            const updatedValues = {
                ...(prevValues || {}),
                [index]: newValue
            };
            return updatedValues;
        });
        e.persist();
        e.target.updatedValue = newValue;
    }

    function handleDropdownChange(e, word, index) {
        const syllables = word.syllable.split('-');
        let repeatArray;
        if (word.repeat === '') {
            repeatArray = new Array(syllables.length).fill(5);
        } else {
            repeatArray = word.repeat.split('-').map(Number);
        }
        if (index >= 0 && index < repeatArray.length) {
            repeatArray[index] = Number(e.target.value);
        }
        word.repeat = repeatArray.join('-');
        saveWord(e, word, index);
    }

    function handleDropdownTranslationChange(e, word, index) {
        const syllables = word.syllable.split('-');
        let repeatArray;
        if (word.translations[index].repeat === '') {
            repeatArray = new Array(syllables.length).fill(5);
        } else {
            repeatArray = word.translations[index].repeat.split('-').map(Number);
        }
        if (index >= 0 && index < repeatArray.length) {
            repeatArray[index] = Number(e.target.value);
        }
        word.translations[index].repeat = repeatArray.join('-');
        handleUpdate(e, word, index);
    }

    return (
        <>
            {wordSyllable[0] !== '' && wordSyllable.map((Phonemes, index) => (
                <div className="lesson-forms" style={{ marginTop: '10px' }} key={index + Phonemes}>
                    <div className="input-field-left" >
                        <input type="hidden" value={translationId} />
                        <input type="text"
                            id={`Phonemes_${index}`}
                            className={`form-control ${phonomesValues && phonomesValues[index] ? '' : 'invalid-input'}  form-image form-margin`}
                            value={phonomesValues ? phonomesValues[index] : ''}
                            onChange={(e) => handleTranslationInputChange(e, index)}
                            onBlur={(e) => { handleForm(selectedWord, e, index); }} />
                        <img src={flagImage === '' ? `${espanolImg}` : `${MediaBaseURL}${flagImage}`} alt="" height="30" width="30" className="dynamic-flags mb-1" />

                        <input
                            type="text"
                            id={`Phonemes_${index}`}
                            className={`form-control ${wordPhonomesValues && wordPhonomesValues[index] ? '' : 'invalid-input'} form-image form-margin`}
                            defaultValue={wordPhonomesValues[index] || ''} 
                            onChange={(e) => handleWordInputChange(e, index)} 
                            onBlur={(e) => { onBlurChange(e, selectedWord, index) }} />
                        <img src={englishImg} className="dynamic-flags-two" alt="" />
                    </div>
                    <div className="worddashbord-dropdown-right custom-select">
                        <div className="lesson-forms-selectors">
                            <select className="form-select form-select-lg"
                                onChange={(e) => handleDropdownTranslationChange(e, selectedWord, index)}
                                defaultValue={wordRepeat && wordRepeat[index] !== undefined ? wordRepeat[index] : ''}>
                                <option value="">Repeat</option>
                                <option value="1">1x</option>
                                <option value="3">3x</option>
                                <option value="5">5x</option>
                            </select>
                        </div>
                        <div className="lesson-forms-selectors">
                            <select
                                className="form-select form-select-lg"
                                onChange={(e) => handleDropdownChange(e, selectedWord, index)}
                                defaultValue={wordRepeat && wordRepeat[index] !== undefined ? wordRepeat[index] : ''}>
                                <option value="">Repeat</option>
                                <option value="1">1x</option>
                                <option value="3">3x</option>
                                <option value="5">5x</option>
                            </select>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}

export default Phonemes;
