/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useHttpService from "../../HttpService";
import Pagination from "../Pagination";
import { stateBackground } from "../../HelperMethods";
import plusImage from "../../assets/images/plus.svg";
import filterImage from "../../assets/images/my-lessons-dropdown.svg";
import Tooltip from "../Tooltip";
import SearchLessons from "../SearchLessons";
import ErrorMessage from "../../assets/images/error-mesgg.svg";
import ShowModal from "../ShowModal";
import AddEditWord from "./AddEditWord";
const Words = () => {
    const [words, setWords] = useState(null);
    const [selectedWord, setSelectedWord] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [languages, setLanguages] = useState([]);
    const [languageStatus, setlanguageStatus] = useState({});
    const [checkedLanguages, setCheckedLanguages] = useState({});
    const [wordsInLessonsOnly, setWordsInLessonsOnly] = useState(false);
    const [lessonId, setLessonId] = useState(0);
    const [resetSearch, setResetSearch] = useState(false);
    const [filtersSelected, setFiltersSelected] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const httpService = useHttpService();
    const pageItems = [10, 20, 30];
    const requestObj = {
        PageNumber: 1,
        PageSize: pageItems[0],
        Name: "",
        State: "",
        LessonId: lessonId,
        FetchWordsInLessons: wordsInLessonsOnly,
    };
    useEffect(() => {
        getWords(requestObj.PageNumber, requestObj.PageSize);
    }, []);
    useEffect(() => {
        getLanguages();
        const fetchedLanguageStatus = {};
        for (const language of languages) {
            if (language.languageId === 2) {
                fetchedLanguageStatus[language.languageId] = true;
            } else {
                fetchedLanguageStatus[language.languageId] = false;
            }
        }
        setlanguageStatus(fetchedLanguageStatus);
    }, []);

    const getWords = (pageNumber, pageSize, selectedWord) => {
        requestObj.PageNumber = pageNumber;
        requestObj.PageSize = pageSize;
        requestObj.Name = document.getElementById("word")?.value;
        requestObj.State = document.getElementById("state")?.value;
        httpService
            .post("/api/words/all", requestObj)
            .then((response) => {
                const rearrangedArray = response.data.data.sort((a, b) => {
                    if (a.state === "Missing" && b.state !== "Missing") {
                        return -1;
                    } else if (a.state !== "Missing" && b.state === "Missing") {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                setWords(rearrangedArray.length ? rearrangedArray : []);
                selectedWord = response.data.data;
                setTotalCount(response.data.totalCount);
            })
            .catch((err) => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                    setShowModal(true);
                } else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                    setShowModal(true);
                } else {
                    setErrorMessage("Something went wrong, please try again or contact support!");
                    setShowModal(true);
                }
            });
    };
    const handleRefreshWords = (selectedWord, isDeleted = false, isPublished = false) => {
        if (words.length && !isDeleted) {
            words.forEach((word, idx) => {
                if (word.wordId === selectedWord.wordId) {
                    words[idx] = selectedWord;
                }
            })
        } else if (isPublished) {
            selectedWord.state = "Published";
            const updatedWords = words.map((word) => {
                if (word.wordId === selectedWord.wordId) {
                    return selectedWord;
                } else {
                    return word;
                }
            });
            setWords(updatedWords);
        }
        else if (selectedWord.state === "Missing Language" || selectedWord.state === 'Missing' || selectedWord.state === 'Unpublished') {
            const updatedWords = words.map((word) => {
                if (word.wordId === selectedWord.wordId) {
                    return selectedWord;
                } else {
                    return word;
                }
            });
            setWords(updatedWords);
        }
        else {
            getWords(requestObj.PageNumber, requestObj.PageSize, selectedWord);
        }
    };
    const handleAddWord = (event) => {
        const word = {
            audioPath: "",
            definition: "",
            flagPath: "",
            language: null,
            languageId: 1,
            phonemes: "",
            phonetic: "",
            sentence: "",
            state: "New",
            syllable: "",
            word: "",
            wordId: 0,
            selectedLanguages: [],
            translations: [],
        };
        event.preventDefault();
        setSelectedWord(word);
    };
    const handleWordClick = (word) => {
        if (!word.syllable.length) {
            word.syllable = word.word;
        }

        const updatedWords = [word, ...words.filter(w => w.wordId !== word.wordId)];
        setWords(updatedWords);
        setSelectedWord(word);
    };
    const getLanguages = () => {
        httpService
            .get(`/api/languages`)
            .then((res) => {
                setLanguages(res.data);
                setCheckedLanguages(res.data);
            })
            .catch((err) => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                    setShowModal(true);
                }
                else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                    setShowModal(true);
                }
                else {
                    setErrorMessage("Something went wrong, please try again or contact support!");
                    setShowModal(true);
                }
            })
            .finally();
    };

    const handleCheckboxClick = (language) => {
        setlanguageStatus((prevChecked) => ({
            ...prevChecked,
            [language.languageId]: !prevChecked[language.languageId],
        }));
        setFiltersSelected(true);
    };

    useEffect(() => {
        fetchCheckedValues();
    }, [languageStatus]);

    const fetchCheckedValues = () => {
        const checkedLanguageIds = Object.keys(languageStatus).filter(
            (languageId) => languageStatus[languageId]
        );

        const data = checkedLanguageIds.map((languageId) => {
            const checkedLanguage = languages.find(
                (language) => language.languageId === parseInt(languageId)
            );
            return checkedLanguage
                ? {
                    name: checkedLanguage.name,
                    languageId: checkedLanguage.languageId,
                    flagPath: checkedLanguage.flagPath,
                }
                : null;
        });
        setCheckedLanguages(data);
    };
    const handleLessonSelect = (lessonId) => {
        setLessonId(lessonId);
        requestObj.LessonId = lessonId;
        getWords(requestObj.PageNumber, requestObj.PageSize, requestObj.LessonId);
        if (lessonId != 0) {
            setFiltersSelected(true);
        }
    }
    const handleLessonWordsOnly = () => {
        setWordsInLessonsOnly(!wordsInLessonsOnly);
        requestObj.FetchWordsInLessons = !wordsInLessonsOnly;
        getWords(requestObj.PageNumber, requestObj.PageSize, requestObj.FetchWordsInLessons);
        setFiltersSelected(!wordsInLessonsOnly);
    }

    const handleClearResults = (event) => {
        event.preventDefault();
        setlanguageStatus((prevChecked) => {
            const updatedStatus = {};
            Object.keys(prevChecked).forEach((languageId) => {
                updatedStatus[languageId] = false;
            });
            return updatedStatus;
        });

        setWordsInLessonsOnly(false);
        setLessonId(0);
        setResetSearch((prevResetSearch) => !prevResetSearch);

        requestObj.LessonId = 0;
        handleLessonSelect(requestObj.LessonId);
        requestObj.FetchWordsInLessons = false;
        requestObj.Name = "";
        requestObj.State = "";

        document.getElementById("word").value = "";
        document.getElementById("state").value = "";

        getWords(requestObj.PageNumber, requestObj.PageSize);
        setFiltersSelected(false);
    };

    const handleFilterChange = () => {
        setFiltersSelected(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <section className="dashbord-main word-dashbord-form word-dashbord-unique">
                <div className="container inner-container">
                    <div className="my-lessons my-dash">
                        <h2>Word Dashboard</h2>
                        <div className="dropdown my-lessons-dropdown leson-dashbord">
                            <a
                                className="btn btn-secondary dropdown-toggle"
                                href="/"
                                onClick={(event) => handleAddWord(event)}
                            >
                                <img className="dasboard-plusico" src={plusImage} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="lessons-input-fielsMain">
                        <div className="wordDashbord-forms">
                            <form>
                                <div className="lesson-forms">
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        className="form-control"
                                        id="word"
                                        placeholder="Word"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            if (e.target.value.length >= 3) {
                                                getWords(1, 10);
                                                handleFilterChange();
                                            }

                                        }}
                                    />
                                </div>
                                <SearchLessons onLessonSelect={handleLessonSelect} key={resetSearch.toString()} />
                                <div className="lesson-forms-selectors lesson-forms">
                                    <select
                                        className="form-select form-select-lg"
                                        defaultValue=""
                                        id="state"
                                        onChange={(e) => {
                                            getWords(1, 10);
                                            handleFilterChange();
                                        }}
                                    >
                                        <option value="">Status</option>
                                        <option value="New">New</option>
                                        <option value="Published">Published</option>
                                        <option value="Unpublished">Unpublished</option>
                                        <option value="Missing">Missing</option>
                                    </select>
                                </div>
                                <div className="suggested-lesson-checkBoxes word-dashbord-checkbox">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={wordsInLessonsOnly} onChange={handleLessonWordsOnly} />
                                        <label>Words Only In Lessons</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <Tooltip text="Filter By Language">
                            <div className="dropdown my-lessons-dropdown wordDashbord-dp">
                                <a
                                    className="btn btn-secondary dropdown-toggle"
                                    href="/"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img className="dashbord-ico" src={filterImage} alt="" />
                                </a>
                                <ul className="dropdown-menu">
                                    <div className="word-dashbord-dropdown">
                                        <div className="suggested-lesson-checkBoxes">
                                            {languages.map((language) => (
                                                <div className="form-check" key={language.languageId}>
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`flexCheckDefault_${language.languageId}`}
                                                    >
                                                        {language.name}
                                                    </label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`flexCheckDefault_${language.languageId}`}
                                                        checked={
                                                            languageStatus[language.languageId] || false
                                                        }
                                                        onChange={() => handleCheckboxClick(language)}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </Tooltip>
                        {filtersSelected && (
                            <div className="clear-filters">
                                <a href="/" onClick={handleClearResults}>Clear Results</a>
                            </div>
                        )}
                    </div>
                    <div className="lesson-title-main lesson-word-dash-main">
                        <div className="lesson-title-left">
                            {words &&
                                words.map((word) => (
                                    <div
                                        className="lesson-title-box cursor"
                                        onClick={() => handleWordClick(word)}
                                        key={word.wordId}
                                    >
                                        {
                                            <>
                                                <div className="lesson-flag-main">
                                                    <div className="lesson-left-flag">
                                                        <h2>{word.word}</h2>
                                                    </div>
                                                    <div className="word-right-button">
                                                        <div
                                                            className={`word-link-button ${stateBackground[
                                                                word.languageId === 0 &&
                                                                    word.phonemes === "" &&
                                                                    word.syllable === ""
                                                                    ? "Missing"
                                                                    : word.state
                                                            ]}`}
                                                        >
                                                            <a href="/" onClick={(e) => e.preventDefault()}>
                                                                {word.languageId === 0 &&
                                                                    word.phonemes === "" &&
                                                                    word.syllable === ""
                                                                    ? "Missing"
                                                                    : word.state}
                                                            </a>
                                                        </div>

                                                        {((word.state === "Unpublished" || word.state === "Published") && word.missingLanguage === "MissingLanguage") && (
                                                            <div style={{ marginLeft: "5px" }}
                                                                className={`word-link-button ${stateBackground["MissingLanguage"]}`}
                                                            >
                                                                <a href="/" onClick={(e) => e.preventDefault()}>
                                                                    Missing Language
                                                                </a>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="lesson-description">
                                                    <span>
                                                        <label>Description:</label> {word.definition}
                                                    </span>
                                                </div>
                                            </>
                                        }
                                    </div>
                                ))}
                            <Pagination
                                pageNo={requestObj.PageNumber}
                                size={requestObj.PageSize}
                                count={totalCount}
                                perPageItems={pageItems}
                                onChange={getWords}
                            />
                        </div>
                        {/* <div className="lesson-title-right word-dashbord-right">
                            {selectedWord && (
                                <AddUpdateWord
                                    word={selectedWord}
                                    refreshWords={handleRefreshWords}
                                    checkedLanguage={checkedLanguages}
                                    languages={languages}
                                    onWordClick={handleWordClick}
                                />
                            )}
                        </div> */}
                        <div className="lesson-title-right word-dashbord-right">
                            <div className="sticky-right">
                                <div className="sticky-scroll">
                                    {selectedWord && (
                                        <AddEditWord
                                            word={selectedWord}
                                            refreshWords={handleRefreshWords}
                                            languages={languages}
                                            onWordClick={handleWordClick}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {showModal && (<ShowModal showModal={showModal} handleCloseModal={handleCloseModal} AddClass={'error-message-mainbox fade'}>
                <div className="error-mesg-main">
                    <div className="del-box">
                        <img src={ErrorMessage}></img>
                        <h2>Error</h2>
                        <span>{errorMessage}</span>
                        <div className="continoue">
                            <a href="/" onClick={(e) => {
                                e.preventDefault();
                                setShowModal(false);
                            }}>Continue</a>
                        </div>
                    </div>
                </div>
            </ShowModal>
            )}
        </>
    );
};

export default Words;
