/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import ShowModal from "./ShowModal";
import AudioRecorder from "./AudioRecorder";
import {
    getLoggedInUserId,
    MediaBaseURL,
    addSpaceAroundHyphen,
} from "../HelperMethods";
import useHttpService from "../HttpService";
import bookImg from "../assets/images/dictionary_icon.svg";
import startImage from "../assets/images/play-btn-white.svg";
import tickImg from "../assets/images/tick.svg";
import speakerImg from "../assets/images/noice-speaker.svg";
import { toast } from "react-toastify";
import LoadingSpinner from "./LoadingSpinner";
import englishImg from "../assets/images/english.svg";
import espanolImg from "../assets/images/espanol.svg";
import Tooltip from "./Tooltip";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import ErrorMessage from "../assets/images/error-mesgg.svg";

const Issues = ({ issues, onReset, result, processedIssueId }) => {
    const [showModal, setShowModal] = useState(false);
    const [showErrModal, setShowErrModal] = useState(false);
    const [showIssueLessonModal, setShowIssueLessonModal] = useState(false);
    const [issue, setIssue] = useState({});
    const [startRecording, setStartRecording] = useState(false);
    const [analyzing, setAnalyzing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const audioRef = useRef(null);
    const translationAudioRef = useRef(null);
    const [phonomesValues, setPhonomesValues] = useState([]);
    const [splitSyllable, setSplitSyllable] = useState([]);
    const [flagPath, setFlagPath] = useState("");
    const [phoneticWords, setPhoneticWords] = useState([]);
    const httpService = useHttpService();
    const [translationPhonomes, setTranslationPhonomes] = useState("");
    const [wordPhonomes, setWordPhonomes] = useState("");
    const [syllable, setSyllable] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [transcribeText, setTranscribeText] = useState("");
    useEffect(() => {
        if (processedIssueId === issue?.issueId) {
            setAnalyzing(false);
            setShowResult(true);
        }
    }, [processedIssueId]);

    const handleIssue = (issue) => {
        setIssue(issue);
        setShowModal(true);
    };

    const handleIssueLesson = (issue) => {
        const wordrepeat = issue.repeat.split("-");
        if (issue && issue.soundsLike && issue.soundsLike.length) {
            const regex = /[-/;]/g;
            setSyllable(issue.soundsLike.replace(regex, "·"));
        } else {
            setSyllable(issue.soundsLike);
        }
        setWordPhonomes(issue.phonemes);
        const phoneticWords = issue.phonemes
            ? issue.phonemes.split("-")
            : Array(issue.phonemesCount).fill(null);
        const WordRepeats = phoneticWords.reduce((acc, val, index) => {
            const repeatValue =
                wordrepeat && wordrepeat[index] ? parseInt(wordrepeat[index]) : 5;
            acc[index] = Array(repeatValue).fill(val).join("-");
            return acc;
        }, {});
        let newArray;
        if (Object.keys(WordRepeats).length) {
            newArray = addSpaceAroundHyphen(WordRepeats);
            setPhoneticWords(newArray);
        } else {
            setPhoneticWords(WordRepeats);
        }
        setSplitSyllable(issue.soundsLike.split("-"));

        let wordTranslation = issues.find((x) => x.wordId === issue.wordId);
        if (wordTranslation !== undefined && wordTranslation !== "") {
            setFlagPath(wordTranslation.translation.flagPath);
            setTranslationPhonomes(wordTranslation.translation?.phonemes);
            const repeat = wordTranslation.translation?.repeat?.split("-");
            const phonemes = wordTranslation.translation?.phonemes?.split("-") || [];
            let newPhonemeValues = [];
            if (phonemes.length > 0) {
                newPhonemeValues = phonemes.reduce((acc, val, index) => {
                    const repeatValue =
                        repeat && repeat[index] ? parseInt(repeat[index]) : 5;
                    acc[index] = Array(repeatValue).fill(val).join("-");
                    return acc;
                }, {});
            }
            setPhonomesValues(newPhonemeValues);
        }
        setIssue(issue);
        setShowResult(false);
        setShowIssueLessonModal(true);
    };

    const handlePlayAudio = () => {
        audioRef.current.play();
    };

    const handlePlayTranslationAudio = () => {
        translationAudioRef.current.play();
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleCloseErrModal = () => {
        setShowErrModal(false);
    };

    const handleCloseIssueLessonModal = () => {
        setStartRecording(false);
        setShowIssueLessonModal(false);
    };


    const handleSave = (transcribedText, transcriptionTime, recordedAudio) => {
        if (transcribedText === '') {
            setErrorMessage('I didnt understand what you said');
            setShowErrModal(true)
            resetComponent();
            return;
        }
        const userId = getLoggedInUserId();
        const phonomesJoined = phonomesValues
            ? Object.values(phonomesValues).join(" ")
            : "";
        const origional = phonomesJoined + ' ' + translationPhonomes + ' ' + translationPhonomes + ' ' + translationPhonomes + ' ' + (issue.translation?.sentence || '');

        const formData = new FormData();
        formData.append('spokenText', transcribedText);
        formData.append('file', recordedAudio);
        var headers = { 'Content-Type': 'multipart/form-data' };
        setIsLoading(true);
        httpService.post(`/api/userLessons/${userId}/analyze-word-issue?userId=${userId}&userLessonIssueId=${issue.issueId}&origionalText=${origional}`, formData, headers)
            .then(res => {
                toast.success('Wait for analysis, you will be notified');
                setAnalyzing(true);
                setStartRecording(false);
            })
            .catch(err => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                    setShowErrModal(true);
                }
                else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                    setShowErrModal(true);
                }
                else {
                    setErrorMessage('Something went wrong, please try again or contact support!');
                    setShowErrModal(true);
                }
            })
            .finally(() => setIsLoading(false));
    };

    const resetComponent = () => {
        setStartRecording(false)
        setAnalyzing(false)
        setIsLoading(false)
    };

    const handleReset = () => {
        setShowIssueLessonModal(false);
        onReset();
    };
    const handlelivetranscription = (transcribedText) => {
        setTranscribeText(transcribedText);
    }
    function addWhiteSpace(input) {
        return input.split('-').join(' - ');
    }
    return (
        <>
            {issues.map((issue) => (
                <div
                    key={issue.issueId}
                    className={`production-main ${issue.issueDetails[issue.issueDetails.length - 1]?.isFixed ? "lesson-tenn" : ""}`}
                >
                    {
                        <>
                            <div className="production-left">
                                <div className="production-image">
                                    <h2>{issue.wordName}</h2>
                                    <div
                                        className="cursor"
                                        onClick={() => {
                                            handleIssue(issue);
                                        }}
                                    >
                                        <a
                                            href="/"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                handleIssue(issue);
                                            }}
                                        >
                                            <img src={bookImg} alt="" />
                                        </a>
                                    </div>
                                </div>
                                <span>
                                    {issue.pronounced === ""
                                        ? `Didn't speak "${issue.wordName}'"`
                                        : `Said "${issue.pronounced}" instead of "${issue.wordName}"`}
                                </span>
                            </div>
                            <div className="production-right">
                                {issue.state !== "Published" && <label>in the works</label>}
                                {issue.state === "Published" && (
                                    <Tooltip text={issue.issueDetails[issue.issueDetails.length - 1]?.isFixed ? "Fixed" : "Start"}>
                                        <div
                                            className="circle-arrows cursor"
                                            onClick={() => {
                                                if (!issue.issueDetails[issue.issueDetails.length - 1]?.isFixed) handleIssueLesson(issue);
                                            }}
                                        >
                                            {issue.issueDetails[issue.issueDetails.length - 1]?.isFixed && (
                                                <span className="ticking-percent">
                                                    {parseInt(issue.issueDetails[issue.issueDetails.length - 1].wordLessonResult.good)}%
                                                </span>
                                            )}
                                            <a
                                                href="/"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (!issue.issueDetails[issue.issueDetails.length - 1]?.isFixed) handleIssueLesson(issue);
                                                }}
                                            >
                                                {
                                                    <img
                                                        src={issue.issueDetails[issue.issueDetails.length - 1]?.isFixed ? tickImg : startImage}
                                                        alt=""
                                                    />
                                                }
                                            </a>
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </>
                    }
                </div>
            ))}

            {showModal && (
                <ShowModal showModal={showModal} handleCloseModal={handleCloseModal}>
                    <div className="production-language-main">
                        <div className="whiteBox-heading whiteBox-popUp popup-issue">
                            <h2>
                                {(issue.wordName || "").charAt(0).toUpperCase() +
                                    (issue.wordName ?? "").slice(1)}
                            </h2>
                            <img src={englishImg} alt="" />
                        </div>
                        <div className="pruhduhkshn-main">
                            <div className="noice-speaker cursor" onClick={handlePlayAudio}>
                                <img src={speakerImg} alt="" />
                                <audio ref={audioRef} preload="auto">
                                    <source
                                        src={MediaBaseURL + issue.audioPath}
                                        type="audio/mp3"
                                    ></source>
                                </audio>
                            </div>
                            <span>{issue?.soundsLike.replace(/[-/;]/g, "·")}</span>
                        </div>
                        <div className="work-terminology-main">
                            <div className="production-popUp">
                                <p>{issue?.definition}</p>
                            </div>
                        </div>
                    </div>
                    <div className="production-language-main">
                        <div className="whiteBox-heading whiteBox-popUp popup-issue">
                            <h2>
                                {(issue.translation?.wordName || "").charAt(0).toUpperCase() +
                                    (issue.translation?.wordName ?? "").slice(1)}
                            </h2>
                            <img src={espanolImg} alt="" />
                        </div>
                        <div className="pruhduhkshn-main">
                            <div
                                className="noice-speaker cursor"
                                onClick={handlePlayTranslationAudio}
                            >
                                <img src={speakerImg} alt="" />
                                <audio ref={translationAudioRef} preload="auto">
                                    <source
                                        src={MediaBaseURL + issue.translation?.audioPath}
                                        type="audio/mp3"
                                    ></source>
                                </audio>
                            </div>
                            <span>{issue.translation?.syllable.replace(/[-/;]/g, "·")}</span>
                        </div>
                        <div className="work-terminology-main">
                            <div className="production-popUp">
                                <p>{issue.translation?.definition}</p>
                            </div>
                        </div>
                    </div>
                </ShowModal>
            )}

            {showIssueLessonModal && (
                <ShowModal
                    showModal={showIssueLessonModal}
                    handleCloseModal={handleCloseIssueLessonModal}
                >
                    <div className="whiteBox-heading terminology lesson-amazingly whitebox-headingwidth">
                        <h2>
                            Lesson:{" "}
                            {(issue.wordName || "").charAt(0).toUpperCase() +
                                (issue.wordName ?? "").slice(1)}
                        </h2>
                        <span className="red-text">({syllable.replace("-", "·")})</span>
                    </div>
                    <div className="steps-main">
                        <div className="step-one">
                            <h2>Step 1 - Click</h2>
                            <span className="start-button start-button-two">start</span>
                        </div>
                        <div className="step-two">
                            <h2>Step 2 - Read Highlighted Text</h2>
                            <span>a - a - a - a - a</span>
                        </div>
                        <div className="step-three">
                            <h2>Step 3 - Complete to Analyze</h2>
                            <a href="/">
                                <span></span>
                            </a>
                        </div>
                    </div>

                    <div className="syllable-learning-main">
                        <div className="syllable-scroll">
                            <div className="syllable-left">
                                {splitSyllable[0] !== "" &&
                                    splitSyllable.map((syllable, index) => (
                                        <span key={index}>Syllable: {syllable}</span>
                                    ))}
                                <span>Full Word</span>
                                <span>Sentence</span>
                            </div>
                            <div
                                className={`syllable-right ${issue.isFixed === false && startRecording === false
                                    ? showResult === false
                                        ? "blur-lesson-seven"
                                        : ""
                                    : startRecording === true
                                        ? "stop-buton"
                                        : ""
                                    }`}
                            >
                                <div className={`syllable-flag-main ${startRecording === false ? "blur-bg" : ""}`}>
                                    {Object.entries(phoneticWords).map(
                                        ([key, value]) =>
                                            value !== null && (
                                                <div key={key}>
                                                    <div className="syllable-flag-top">
                                                        <img
                                                            src={MediaBaseURL + flagPath}
                                                            alt=""
                                                            height="30"
                                                            width="30"
                                                        />
                                                        <span>{addWhiteSpace(phonomesValues[key])}</span>
                                                    </div>
                                                    <div className="syllable-flag-bottom">
                                                        <img
                                                            src={englishImg}
                                                            alt=""
                                                            height="30"
                                                            width="30"
                                                        />
                                                        <span>{phoneticWords[key]}</span>
                                                    </div>
                                                </div>
                                            )
                                    )}
                                </div>
                                <div className={`syllable-flag-main ${startRecording === false ? "blur-bg" : ""}`}>
                                    <div className="syllable-flag-top">
                                        <img
                                            src={MediaBaseURL + flagPath}
                                            alt=""
                                            height="30"
                                            width="30"
                                        />
                                        <span>{addWhiteSpace(translationPhonomes)}{'  '}{addWhiteSpace(translationPhonomes)}{'  '}{addWhiteSpace(translationPhonomes)}</span>
                                    </div>
                                    <div className="syllable-flag-bottom">
                                        <img src={englishImg} alt="" />
                                        <span>{wordPhonomes}{'  '}{wordPhonomes}{'  '}{wordPhonomes}</span>
                                    </div>
                                </div>
                                <div className={`syllable-flag-main ${startRecording === false ? "blur-bg" : ""}`}>
                                    <div className="syllable-flag-top">
                                        <img
                                            src={MediaBaseURL + flagPath}
                                            alt=""
                                            height="30"
                                            width="30"
                                        />
                                        <span>{issue.translation?.sentence}</span>
                                    </div>
                                    <div className="syllable-flag-bottom">
                                        <img src={englishImg} alt="" />
                                        <span>{issue.sentence}</span>
                                    </div>
                                </div>
                                {issue.isFixed === false &&
                                    startRecording === false &&
                                    analyzing === false &&
                                    showResult === false && (
                                        <div className="start-center">
                                            {" "}
                                            <a
                                                href="/"
                                                className="start-button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setStartRecording(true);
                                                }}
                                            >
                                                Start
                                            </a>
                                        </div>
                                    )}
                                {((issue.isFixed === true &&
                                    issue.issueDetails.length > 0 &&
                                    issue.issueDetails.some(
                                        (detail) => detail.status === "InProgress"
                                    )) ||
                                    analyzing) && (
                                        <div className="white-loading-Speechbox loading-modal white-loading-modalBox">
                                            <a href="/" onClick={(e) => e.preventDefault()}>
                                                <h2>Analyzing</h2>
                                                <figure>
                                                    <div className="progres">
                                                        <div className="progres-bar progres-bar-info progres-bar-striped active"></div>
                                                    </div>
                                                    <figcaption className="info"></figcaption>
                                                </figure>
                                            </a>
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div>
                            {transcribeText && transcribeText.trim() !== '' && (
                                <b>Transcribed Text: {transcribeText}</b>
                            )}
                        </div>
                        <div className="stop-btn-small">
                            {startRecording && <AudioRecorder
                                handleSave={(transcribedText, transcriptionTime, recordedAudio) => handleSave(transcribedText, transcriptionTime, recordedAudio)}
                                handleStartRecording={setStartRecording}
                                handlelivetranscription={handlelivetranscription}
                            />}
                        </div>

                    </div>

                    {showResult && (
                        <>
                            <div className="circular-progress-bar">
                                <div className="progresss">
                                    <CircularProgressbar
                                        value={parseInt(issues.find(issue => issue.issueId === processedIssueId).percent)}
                                        maxValue={100}
                                        text={`${parseInt(issues.find(issue => issue.issueId === processedIssueId).percent)}/100`}
                                        styles={buildStyles({
                                            textColor: "#000",
                                            pathColor: "#0f7a3c",
                                            trailColor: "#d6d6d6",
                                        })}
                                    />
                                </div>
                            </div>

                            <div className="complete-lesson">
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleReset();
                                    }}
                                >
                                    Retest
                                </a>
                                <a
                                    href="/"
                                    className="completeBtn"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowIssueLessonModal(false);
                                    }}
                                >
                                    Complete
                                </a>
                            </div>
                        </>
                    )}

                    {!showResult && (
                        <div className="lang-main">
                            <div className="eng-main">
                                <div className="eng-word-block">
                                    <h2>English Word:</h2>
                                    <div
                                        className="noice-speaker cursor"
                                        onClick={handlePlayAudio}
                                    >
                                        <img src={speakerImg} alt="" />
                                        <audio ref={audioRef} preload="auto">
                                            <source
                                                src={MediaBaseURL + issue.audioPath}
                                                type="audio/mp3"
                                            ></source>
                                        </audio>
                                    </div>
                                </div>
                                <div className="eng-proncia">
                                    <h2>
                                        {(issue.wordName || "").charAt(0).toUpperCase() +
                                            (issue.wordName ?? "").slice(1)}
                                        :
                                        <span className="red-text">
                                            {" "}
                                            ({syllable.replace("-", "·")})
                                        </span>
                                    </h2>
                                </div>
                                <span className="lang-main-para">{issue?.definition}</span>
                            </div>
                            <div className="native-lang-main">
                                <div className="eng-word-block">
                                    <h2>Native Language Word:</h2>
                                    <div
                                        className="noice-speaker cursor"
                                        onClick={handlePlayTranslationAudio}
                                    >
                                        <img src={speakerImg} alt="" />
                                        <audio ref={translationAudioRef} preload="auto">
                                            <source
                                                src={MediaBaseURL + issue.translation?.audioPath}
                                                type="audio/mp3"
                                            ></source>
                                        </audio>
                                    </div>
                                </div>
                                <div className="eng-proncia">
                                    <h2>
                                        {(issue.translation?.wordName || "")
                                            .charAt(0)
                                            .toUpperCase() +
                                            (issue.translation?.wordName ?? "").slice(1)}
                                        :
                                        <span className="red-text">
                                            {" "}
                                            {issue.translation?.syllable.replace(/[-/;]/g, "·")}
                                        </span>
                                    </h2>
                                </div>
                                <span className="lang-main-para">
                                    {issue.translation?.definition}
                                </span>
                            </div>
                            {startRecording === false && analyzing === true && (
                                <div className="complete-lesson">
                                    <a
                                        href="/"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleReset();
                                        }}
                                    >
                                        Retest
                                    </a>
                                </div>
                            )}
                        </div>
                    )}
                </ShowModal>
            )}
            {showErrModal && (<ShowModal showModal={showErrModal} handleCloseModal={handleCloseErrModal} AddClass={'error-message-mainbox fade'}>
                <div class="error-mesg-main">
                    <div class="del-box">
                        <img src={ErrorMessage}></img>
                        <h2>Error</h2>
                        <span>{errorMessage}</span>
                        <div class="continoue">
                            <a href="/" onClick={(e) => {
                                e.preventDefault();
                                setShowErrModal(false);
                            }}>Continue</a>
                        </div>
                    </div>
                </div>
            </ShowModal>
            )}
            {isLoading && <LoadingSpinner />}
        </>
    );
};

export default Issues;
