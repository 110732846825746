/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import Tooltip from "./Tooltip";
import Microphone from "./Microphone";

const AudioRecorder = ({ handleSave, handleStartLesson,handlelivetranscription }) => {
  const [recording, setRecording] = useState(true);
  const [transcribedText, setTranscribedText] = useState("");
  const [transcriptionTime, setTranscriptionTime] = useState(0);
  const [startButton, setStartButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allowMicrophone, setAllowwMicrophone] = useState(false);
  
  const socketRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const recordedChunksRef = useRef([]);

  let mediaStream = null;

  useEffect(() => {
    if (recording) {
      getAudioRecordingPermission();
    } else {
      stopRecording();
    }

    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [recording]);

    useEffect(() => {
    handlelivetranscription(transcribedText)
  }, [transcribedText]);

    useEffect(() => {
        return () => {
            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.stop();
                mediaRecorderRef.current.stream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
        };
    }, []);

  const getAudioRecordingPermission = () => {
      setAllowwMicrophone(true)
    startRecording();
  };

  const startRecording = () => {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((mediaStream) => {
        setAllowwMicrophone(false)
      if (!MediaRecorder.isTypeSupported("audio/webm"))
        return alert("Browser not supported");
          const mediaRecorder = new MediaRecorder(mediaStream, {
        mimeType: "audio/webm",
      });
        mediaRecorderRef.current = mediaRecorder;
      const socket = new WebSocket("wss://api.deepgram.com/v1/listen", [
        "token",
        "881ee64eea614850e4c8979566c11474ecfc0296",
      ]);
      socket.onopen = () => {
        console.log({ event: "onopen" });
        mediaRecorder.addEventListener("dataavailable", async (event) => {
          if (event.data.size > 0 && socket.readyState === 1) {
              socket.send(event.data);
              recordedChunksRef.current.push(event.data);
          }
        });
        mediaRecorder.start(10);
        setStartButton(true);
      };
      socket.onmessage = (message) => {
        const received = JSON.parse(message.data);
        if (received.channel && received.channel.alternatives[0].transcript) {
          const transcript = received.channel.alternatives[0].transcript;
          if (transcript) {
            setTranscribedText((transcribedText) => transcribedText + " " + transcript);
            setTranscriptionTime((transcriptionTime) => transcriptionTime + received.duration);
          }
        } else {
          console.log("not found");
        }
      };

      socket.onclose = () => {
        console.log({ event: "onclose" });
      };
      socket.onerror = (error) => {
        console.log({ event: "onerror", error });
      };
      socketRef.current = socket;
    });
  };

    const stopRecording = () => {
        var recordedAudio;
        if (socketRef.current) {
                if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
                    mediaRecorderRef.current.stop();
                }
          socketRef.current.close();
            setStartButton(false);
            if (recordedChunksRef.current.length === 0) {
                alert('No audio to save.');
            } else {
                recordedAudio = new Blob(recordedChunksRef.current, { type: 'audio/mp3' });
            }
            handleSave(transcribedText, transcriptionTime, recordedAudio);
            recordedChunksRef.current = [];
        }
    };


  return (
    <>
    {allowMicrophone && <Microphone />}
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        setRecording(false);
      }}
    >
      <Tooltip text="Stop">
        <div className={startButton ? "stop-btn" : "lesson-start-button-main"}>
          <span></span>
        </div>
      </Tooltip>
    </a>
    </>
  );
};

export default AudioRecorder;
