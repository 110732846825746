import React from 'react';

const HighlightLessonIssues = ({ paragraph, wordsToHighlight }) => {
    const highlightWords = (text) => {
        const words = text.split(' ');
        return words.map((word, index) => {
            const wordWithoutPunctuation = removePunctuation(word);
            
            const highlight = wordsToHighlight.find(
                (wordtxt) =>
                    wordtxt.wordName.toLowerCase() === wordWithoutPunctuation.toLowerCase() &&
                    wordtxt.wordIndex === index
            );

            const color = highlight
                ? highlight.type === 'Mistake'
                    ? 'red'
                    : highlight.type === 'Missing'
                        ? '#FBAB40'
                        : ''
                : '';

            return (
                <span key={index} style={{ color }}>
                    {`${word} `}
                </span>
            );
        });
    };

    return <span>{highlightWords(paragraph)}</span>;
};

const removePunctuation = (word) => {
    return word.replace(/^[^\w\s]+|[^\w\s]+$/g, '');
}

export default HighlightLessonIssues;
