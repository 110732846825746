import { useEffect, useState } from "react";
import SelectList from "../SelectList";
import { countryData, MediaBaseURL } from '../../HelperMethods';
import LoadingSpinner from '../LoadingSpinner';
import Tooltip from "../Tooltip";
import useHttpService from "../../HttpService";
import ShowModal from "../ShowModal";
import ErrorMessage from "../../assets/images/error-mesgg.svg";
import savedImage from '../../assets/images/saved-icon.svg';
import focusImage from '../../assets/images/focus-icon.svg';
import plusImage from '../../assets/images/plus.svg';
import Language from "./Language";
import Phonemes from "./Phonemes";
import { toast } from "react-toastify";

const AddEditWord = ({ word, refreshWords, languages, onWordClick }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrModal, setShowErrModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedWord, setSelectedWord] = useState(word);
    const [showModal, setShowModal] = useState(false);
    const [languageId, setLanguageId] = useState('2');
    const [flagImage, setFlagImage] = useState('');
    const [wordSyllable, setWordSyllable] = useState([]);
    const [translationId, setTranslationId] = useState('');
    const [syllables, setSyllables] = useState('');
    const [sentences, setSentences] = useState('');
    const [phonomesValues, setPhonomesValues] = useState({});
    const [wordPhonomesValues, setWordPhonomesValues] = useState([]);
    const [isDefinationSaved, setIsDefinationSaved] = useState(Boolean(word.definition));
    const [wordState, setWordState] = useState('');
    const [isLessonSaved, setIsLessonSaved] = useState(Boolean(
        word.definition !== undefined && word.definition !== '' &&
        word.phonetic !== undefined && word.phonetic !== '' &&
        word.syllable !== undefined && word.syllable !== '' &&
        word.word !== undefined && word.word !== ''
    ));

    const stateObject = {
        word,
        flagImage,
        languageId,
        wordPhonomesValues,
        setWordPhonomesValues,
        phonomesValues,
        setPhonomesValues
    };

    const httpService = useHttpService();

    useEffect(() => {
        setSelectedWord(word);
        if (word.translations[0]?.languageId)
            setLanguageId(word.translations[0]?.languageId);
        const language = languages.find(lang => lang.languageId == languageId);
        if (language) {
            if (languages.length === 0) {
                languages.push(language);
            }
            setFlagImage(language.flagPath);
            setLanguageId(language.languageId);
        }
        else {
            languages = [];
        }
        setWordSyllable(word.syllable.split('-'));
        setSyllables(word.translations[0]?.syllable ?? '');
        let phonemes = [];
        const wordTranslation = word.translations.find(trans => trans.languageId == languageId);
        if (wordTranslation) {
            setTranslationId(wordTranslation.translationId);
            phonemes = wordTranslation.phonemes.split('-');
            setSentences(wordTranslation.sentence ?? '');
        }
        const phonem = word.phonemes.split('-');
        setWordPhonomesValues(phonem);
        let newPhonemeValues = [];
        if (phonemes[0] === undefined || phonemes[0] === '') {
            newPhonemeValues = phonem.reduce((acc, val, index) => {
                acc[index] = '';
                return acc;
            }, {});
        } else {
            newPhonemeValues = phonemes.reduce((acc, val, index) => {
                acc[index] = val;
                return acc;
            }, {});
        }
        setPhonomesValues(newPhonemeValues);
    }, [word]);
    const handleCloseErrModal = () => {
        setShowErrModal(false);
    };
    const closeLanguageModal = () => {
        setShowModal(false);
    };
    const handleCountryChange = (selectedOption) => {
    }

    const saveWord = (e, wordObj, indx) => {
        setIsLoading(true);
        if (wordObj.word !== '' && wordObj.phonetic !== '' && wordObj.definition !== '' && wordObj.syllable !== '' && wordObj.phonemes === '') {
            wordObj.state = 'Missing Language';
        }
        else if (wordObj.word !== '' && wordObj.phonetic === '' && wordObj.definition === '' && wordObj.syllable === '' && wordObj.phonemes === '') {
            wordObj.state = 'New';
        }
        else if (wordObj.word === '' || wordObj.phonetic === '' || wordObj.definition === '' || wordObj.syllable === '' || wordObj.phonemes === '') {
            wordObj.state = 'Missing';
        }
        else if (wordState === "Published" || wordObj.state === "Published") {
            wordObj.state = 'Published';
        } else {
            wordObj.state = 'Unpublished';
        }
        httpService.post(`/api/words`, wordObj)
            .then(res => {
                if (res.data === 0) {
                    const message = <label>The word <span className="popup-model-text"> {wordObj.word} </span> already exists.</label>;
                    setErrorMessage(message);
                    setShowErrModal(true);
                    return;
                }
                handleSomeEvent();
                setIsDefinationSaved(true)
                selectedWord.wordId = res.data;
                if(selectedWord.word.length > 0 && selectedWord.phonetic.length > 0 && selectedWord.syllable.length > 0 && selectedWord.definition.length > 0){
                    setIsLessonSaved(true);
                }
                else setIsLessonSaved(false);
                refreshWords(wordObj);
            })
            .catch(err => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                    setShowErrModal(true)
                }
                else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                    setShowErrModal(true)
                }
                else {
                    setErrorMessage('Something went wrong, please try again or contact support!');
                    setShowErrModal(true)
                }
            })
            .finally(() => { setIsLoading(false) });
    };

    const handleImageClick = (event, language) => {
        event.preventDefault();
        const { languageId, flagPath } = language;
        setLanguageId(languageId);
        setFlagImage(flagPath);
        let newPhonemeValues = '', languageTranslations = word.translations.find(x => x.languageId === languageId);
        if (languageTranslations) {
            const { phonemes } = languageTranslations;
            if (phonemes !== '') {
                const phonemeValues = phonemes ? phonemes.split('-') : word.translations[1].phonemes.split('-');

                newPhonemeValues = phonemeValues.reduce((acc, val, index) => {
                    acc[index] = val;
                    return acc;
                }, {});
            } else {
                newPhonemeValues = phonemes.split('-').reduce((acc, val, index) => {
                    acc[index] = '';
                    return acc;
                }, {});
            }
            setPhonomesValues(newPhonemeValues);
            setTranslationId(languageTranslations.translationId);
        } else {
            newPhonemeValues = word.phonemes.split('-').reduce((acc, val, index) => {
                acc[index] = '';
                return acc;
            }, {});
            setPhonomesValues(newPhonemeValues);
            setTranslationId('');
        }
    };
    const handleSomeEvent = () => {
        if (onWordClick) {
            refreshWords(selectedWord);
            onWordClick(selectedWord);
        }
    };
    const handleForm = (wordObj, e, indx) => {
        let translation = word.translations.find(x => x.languageId === languageId);
        if (wordObj.translations[0]?.translationId === "" || wordObj.translations[0]?.translationId === undefined || translation === undefined) {
            saveForm(wordObj, e, indx)
        } else {
            handleUpdate(e, indx);
        }
    }
    const saveForm = (wordObj, e, indx) => {
        if (languageId === '' || languageId === undefined) {
            setErrorMessage('Please select a language');
            setShowErrModal(true);
            return;
        }
        setIsLoading(true);
        wordObj.phonemes = fetchPhonomesValues(e, indx);
        wordObj.languageId = languageId === '' ? 0 : languageId;
        wordObj.syllable = syllables ? syllables : word.syllable;
        wordObj.repeat = '';
        wordObj.sentence = sentences;
        httpService.post(`/api/words/${selectedWord.wordId}/add-translation`, wordObj)
            .then(res => {
                setTranslationId(res.data);
                let translation = {
                    translationId: res.data || '',
                    syllable: wordObj.syllable || '',
                    phonemes: wordObj.phonemes || '',
                    sentence: sentences || '',
                    repeat: selectedWord.translations[0]?.repeat || '',
                    wordId: selectedWord.wordId || '',
                    languageId: wordObj.languageId || ''
                };
                const existingTranslationIndex = word.translations.findIndex(trans => trans.languageId === wordObj.languageId);
                if (existingTranslationIndex !== -1) {
                    word.translations[existingTranslationIndex] = translation;
                } else {
                    word.translations.push(translation);
                }
                handleSomeEvent();
                toast.success('Record saved.');
            })
            .catch(err => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                    setShowErrModal(true);
                } else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                    setShowErrModal(true);
                } else {
                    setErrorMessage('Something went wrong, please try again or contact support!');
                    setShowErrModal(true);
                }
            })
            .finally(() => setIsLoading(false));
    };


    const handleUpdate = (e, indx) => {
        setIsLoading(true);
        const phonomesValues = fetchPhonomesValues(e, indx);
        let translation = word.translations.find(x => x.languageId === languageId)
        translation = {
            ...translation,
            translationId: translationId,
            syllable: syllables,
            phonemes: phonomesValues,
            sentence: sentences,
            repeat: selectedWord.translations[0].repeat,
            wordId: selectedWord.wordId,
            languageId: languageId
        }
        word && word.translations.forEach((currTrans, index) => {
            if (currTrans.languageId === languageId) {
                word.translations[index] = translation
            }
        })
        setSelectedWord(word)
        httpService.put(`/api/words/${selectedWord.wordId}/update-translation`, translation)
            .then(res => {
                handleSomeEvent();
                toast.success('Record updated successfully.');
            })
            .catch(err => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                    setShowErrModal(true)
                }
                else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                    setShowErrModal(true)
                }
                else {
                    setErrorMessage('Something went wrong, please try again or contact support!');
                    setShowErrModal(true)
                }
            })
            .finally(() => setIsLoading(false));
    };
    const fetchPhonomesValues = (e, indx) => {
        let concatenatedValues = '';
        for (const index in phonomesValues) {
            const value = phonomesValues[index];
            if (value) {
                concatenatedValues += value + '-';
            } else {
                concatenatedValues += phonomesValues[index] + '-';
            }
        }
        if (concatenatedValues === '') {
            return '';
        }

        return concatenatedValues.slice(0, -1);
    };

    const handleDelete = (event) => {
        event.preventDefault();

        httpService.remove(`/api/words?wordId=${selectedWord.wordId}`)
            .then(res => {
                toast.success(`Lesson deleted successfully.`);
                refreshWords(selectedWord, true);
            })
            .catch(err => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                    setShowErrModal(true)
                }
                else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                    setShowErrModal(true)
                }
                else {
                    setErrorMessage('Something went wrong, please try again or contact support!');
                    setShowErrModal(true)
                }
            });
    }

    const handleState = (event, state) => {
        event.preventDefault();
        if (state === 'Published') {
            const errors = [];
            if (selectedWord.word.length === 0) {
                errors.push("Title is empty");
            }
            if (fetchPhonomesValues() === '') {
                errors.push("Phonome is empty");
            }
            if (selectedWord.phonetic.length === 0) {
                errors.push("Phonetic is empty");
            }
            if (selectedWord.definition.length === 0) {
                errors.push("Definition is empty");
            }
            if (selectedWord.syllable.length === 0) {
                errors.push("Syllable is empty");
            }
            if (selectedWord.sentence.length === 0) {
                errors.push("Sentence is empty");
            }
            if (errors.length > 0) {
                const validation = errors.map((error, index) => (
                    <p key={index}>{`${index + 1}. ${error}`}</p>
                ));
                setErrorMessage(validation);
                setShowErrModal(true);
                return;
            }
        }
        setWordState(state);
        httpService.put(`/api/words/${selectedWord.wordId}/update-state?wordId=${selectedWord.wordId}&state=${state}`)
            .then(res => {
                toast.success(`Word ${state}ed successfully.`);
                const isPublished = state == "Published" ? true : false;
                refreshWords(selectedWord, true, isPublished);
            })
            .catch(err => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                    setShowErrModal(true)
                }
                else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                    setShowErrModal(true)
                }
                else {
                    setErrorMessage('Something went wrong, please try again or contact support!');
                    setShowErrModal(true)
                }
            });
    }
    return (
        <>
            <div className="amazingly-main">
                <input type="hidden" className="form-control" id="floatingInput" aria-describedby="word" value={selectedWord.wordId} />
                <div className="mb-3 lesson-forms">
                    <div className="form-floating">
                        <input
                            autoComplete="off"
                            type="text"
                            className={`form-control ${selectedWord.word.length > 0 && !selectedWord.word.includes(' ') ? '' : 'invalid-input'}`}
                            id="floatingInput"
                            placeholder="Word Title"
                            value={selectedWord.word}
                            onBlur={(e) => {
                                if (selectedWord.word && selectedWord.word.includes(' ')) {
                                    setErrorMessage("Space is not allowed in the word title.");
                                    setShowErrModal(true)
                                    return;
                                }
                                saveWord(e,selectedWord);
                            }}
                            onChange={(e) => setSelectedWord({ ...selectedWord, word: e.target.value })}
                        />
                        <label htmlFor="floatingInput">Word Title</label>
                    </div>
                </div>

                <div className="difficulty-dropdown header-dp">
                    <SelectList data={countryData} handleSelectChange={handleCountryChange} defaultSelectedOption={countryData[0]} />
                </div>
            </div>
            <div className="phonetic-main">
                <div className="form-floating">
                    <input autoComplete="off" type="text" className={`form-control ${selectedWord.phonetic.length > 0 ? '' : 'invalid-input'}`} id="floatingInput" placeholder="Phonetic" value={selectedWord.phonetic} onBlur = {(e) => {saveWord(e,selectedWord);}} onChange={(e) => setSelectedWord({ ...selectedWord, phonetic: e.target.value })}/>
                    <label htmlFor="floatingInput">Phonetic</label>
                </div>
                <div className="form-floating text-box">
                    <textarea className={`form-control ${selectedWord.definition.length > 0 ? '' : 'invalid-input'} form-Textarea`} placeholder="Definition" id="floatingTextarea" onChange={(e) => {setSelectedWord({ ...selectedWord, definition: e.target.value }); setIsDefinationSaved(false)}} onBlur={(e) => {saveWord(e,selectedWord);}} value={selectedWord.definition}></textarea>
                    <label htmlFor="floatingTextarea">Definition</label>
                </div>
                <div className="focus-saved">
                    <img src={isDefinationSaved && selectedWord.definition.length > 0 ? savedImage : focusImage} alt="" />
                    <span>{isDefinationSaved && selectedWord.definition.length > 0 ? 'Saved Successfully' : 'Not Saved'}</span>
                </div>
                <div className="form-floating">
                    <input type="text" className={`form-control ${selectedWord.syllable.length > 0 ? '' : 'invalid-input'}`} id="floatingInput" placeholder="Syllable" value={selectedWord.syllable} onBlur = {(e) => {saveWord(e,selectedWord);}} onChange={(e) => {setSelectedWord({ ...selectedWord, syllable: e.target.value })}}/>
                    <label htmlFor="floatingInput">Syllable</label>
                </div>
                <div className="lesson-parabox-notsaved">
                    <img src={isLessonSaved ? savedImage : focusImage} alt="" />
                    <span>{isLessonSaved ? 'Lesson Saved Successfully' : 'Lesson Not Saved'}</span>
                </div>
            </div>
            <div className="wordDashbord-Languages-dp suggested-lessons">
                <div className="my-lessons my-dash">
                    <h2>Languages</h2>
                    <Tooltip text="Add Languages">
                        <div className="book-img cursor" onClick={() => { setShowModal(true) }}>
                            <a href="/" onClick={(event) => { event.preventDefault(); setShowModal(true); }}>
                                <img src={plusImage} alt="" />
                            </a>
                        </div>
                    </Tooltip>
                </div>
                <Language showLanguage={showModal} handleCloseModal={closeLanguageModal} />
                <div className="wordDashbord-images">
                    {languages && languages.map((language) => (
                        <div key={language.languageId}>
                            {word.selectedLanguages && word.selectedLanguages.includes(language.languageId) ? (
                                <div data-tooltip-id="my-tooltip" data-tooltip-content={language.name} key={language.languageId + language.name}>
                                    <Tooltip text={language.name}>
                                        <img
                                            src={MediaBaseURL + language.flagPath}
                                            alt=""
                                            id={`flexCheckDefault_${language.languageId}`}
                                            onClick={(e) => handleImageClick(e, language)}
                                            style={{ opacity: languageId === language.languageId ? 1 : 0.5 }}
                                        />
                                    </Tooltip>
                                </div>
                            ) : (
                                <div data-tooltip-id="my-tooltip" data-tooltip-content={language.name} key={language.languageId}>
                                    <Tooltip text={language.name}>
                                        <a href="/" onClick={(e) => handleImageClick(e, language)}>
                                            <img
                                                src={MediaBaseURL + language.flagPath}
                                                alt=""
                                                id={`flexCheckDefault_${language.languageId}`}
                                                style={{ opacity: languageId === language.languageId ? 1 : 0.5 }}
                                            />
                                        </a>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <div className="wordDashbord-LanguagesForms">
                <form>
                    {wordSyllable[0] !== '' && <div className="lesson-forms">
                        <label className="amaz-heading">{word.syllable.replace(/[-/;]/g, '·')}</label>
                    </div>}
                    {wordSyllable[0] !== '' && <Phonemes stateObject={stateObject} handleForm={handleForm} saveWord={saveWord} handleUpdate={handleUpdate} />}
                    {wordSyllable[0] !== '' && <div className="sentence-div">
                        <div className="lesson-forms sentence ">
                            <div className="form-floating input-field-left mb-1">
                                <input autoComplete="off" type="text" className={`form-control ${sentences ? '' : 'invalid-input'}`} id="floatingInputS" placeholder="Sentence Translation" value={sentences} onBlur={(e) => { handleForm(selectedWord, e); }} onChange={(e) => { setSentences(e.target.value); }} />
                                <label htmlFor="floatingInput">Sentence Translation</label>
                            </div>
                        </div>
                        <div className="lesson-forms sentence">
                            <div className="form-floating input-field-left">
                                <input autoComplete="off" type="text" className={`form-control ${selectedWord.sentence.length > 0 ? '' : 'invalid-input'}`} id="floatingInput" placeholder="Sentence" value={selectedWord.sentence} onBlur={(e) => { saveWord(e, selectedWord); }} onChange={(e) => { setSelectedWord({ ...selectedWord, sentence: e.target.value }); }} />
                                <label htmlFor="floatingInput">Sentence</label>
                            </div>
                        </div>
                    </div>}
                </form>
            </div>
            <div className="del-publish-buttons word-delPublish-btns">
                <div className="del-btn">
                    <a href="/" onClick={(event) => { handleDelete(event) }}>Delete</a>
                </div>
                <div className="publish-unbublish-btns">
                    <a href="/" className="un-publish" onClick={(event) => { handleState(event, 'Unpublished') }}>Unpublish</a>
                    <a href="/" className="publish" onClick={(event) => { handleState(event, 'Published') }}>Publish</a>
                </div>
            </div>
            {isLoading && <LoadingSpinner />}
            {showErrModal && (
                <ShowModal showModal={showErrModal} handleCloseModal={handleCloseErrModal} AddClass={'error-message-mainbox fade'}>
                    <div className="error-mesg-main">
                        <div className="del-box">
                            <img src={ErrorMessage}></img>
                            <h2>Error</h2>
                            <div className="error-summary">
                                <div className="error-summary-two">
                                    {errorMessage}
                                </div>
                            </div>
                            <div className="continoue">
                                <a href="/" onClick={(e) => {
                                    e.preventDefault();
                                    setShowErrModal(false);
                                }}>Continue</a>
                            </div>
                        </div>
                    </div>
                </ShowModal>
            )}
        </>
    );
}

export default AddEditWord;
