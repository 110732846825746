import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import useHttpService from "../../HttpService";
import ShowModal from "../ShowModal";
import ErrorMessage from "../../assets/images/error-mesgg.svg";

const AIModels = () => {
    const [models, setModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState('');
    const httpService = useHttpService();
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrModal, setShowErrModal] = useState(false);

    useEffect(() => {
        getModels();
    }, []);

    const getModels = () => {
        httpService
            .get("/api/Settings")
            .then((response) => {
                const modelsData = response?.data;
                setModels(modelsData);
                const activeModel = modelsData.find(model => model.isActive);
                if (activeModel) {
                    setSelectedModel(activeModel.settingId); // Set the active model's id as the default selected value
                }
            })
            .catch((err) => {
                setErrorMessage('Something went wrong, please try again or contact support!');
                setShowErrModal(true);
            });
    };

    const handleModelChange = (event) => {
        const selectedModel = event.target.value;
        setSelectedModel(selectedModel);
        updateModel(selectedModel);
    };

    const updateModel = (settingId) => {
        httpService.put(`/api/Settings/update-model?settingId=${settingId}&isActive=true`)
            .then(res => {
                toast.success("Model updated successfully.");
            })
            .catch(err => {
                setErrorMessage('Something went wrong, please try again or contact support!');
                setShowErrModal(true);
            })
            .finally();
    };

    const handleCloseErrModal = () => {
        setShowErrModal(false);
    };
    return (
        <>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <label>Select Model</label>
                    <div className="col-md-3">
                        <div className="input-group">
                            <select className="form-select" value={selectedModel} onChange={handleModelChange}>
                                {models
                                    .map((model) => (
                                        <option key={model.id} value={model.settingId}>
                                            {model.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {
                showErrModal && (<ShowModal showModal={showErrModal} handleCloseModal={handleCloseErrModal} AddClass={'error-message-mainbox fade'}>
                    <div className="error-mesg-main">
                        <div className="del-box">
                            <img src={ErrorMessage} alt="Error Message" />
                            <h2>Error</h2>
                            <span>{errorMessage}</span>
                            <div className="continoue">
                                <a href="/" onClick={(e) => {
                                    e.preventDefault();
                                    setShowErrModal(false);
                                }}>Continue</a>
                            </div>
                        </div>
                    </div>
                </ShowModal>
                )}
        </>
    );
};

export default AIModels;
